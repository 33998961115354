// @flow
import * as React from 'react';
import { graphql } from 'gatsby';
import withStyles, {
  type WithStyles,
} from '@plugins/material-ui/hocs/withStyles';
import type { Theme } from '@material-ui/core/styles/createMuiTheme';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery/useMediaQueryTheme';
import compose from 'recompose/compose';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import map from 'lodash/map';
import classnames from 'classnames';

import ResPadding from '~plugins/material-ui/components/ResPadding';
import RichText from '~plugins/prismic/components/RichText';
import FileImage from '~plugins/prismic/components/FileImage';
import withNodePage, {
  type WithNodePageProps,
} from '~plugins/prismic/hocs/withNodePage';
import FieldLink from '~plugins/prismic/components/FieldLink';
import StyledNotchedButton from '~components/StyledNotchedButton';
import getBreakPointFactor from '~plugins/material-ui/components/ResPadding/helpers/getPaddingFactor';
import useBackgroundPatternFile from '~hocs/withBodySectionSlicePattern/hooks/useBackgroundPatternFile';
import type { Prismic404Page } from '~schema';

import image from '../images/vr-girl.png';
import imageIntech from '../images/error-intech.png';

export type ClassKey =
  | 'root'
  | 'backgroundImage'
  | 'wrapper'
  | 'itemsGridContainer'
  | 'itemPrimaryWrapper'
  | 'itemSecondaryWrapper'
  | 'itemTitle'
  | 'itemDescription'
  | 'itemButtonWrapper'
  | 'itemButton'
  | 'itemImage'
  | 'itemImageIntech';

const styles = (theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'relative !important',
  },
  backgroundImage: {
    width: '100%',
    height: '100%',
    position: 'absolute !important',
    top: 0,
    left: 0,
    opacity: 0.1,
    pointerEvents: 'none',
  },
  wrapper: {
    zIndex: 1,
    marginTop: theme.mixins.toolbar.minHeight,
  },
  itemsGridContainer: {},
  itemPrimaryWrapper: {},
  itemSecondaryWrapper: {},
  itemTitle: {
    color: theme.palette.common.black,
  },
  itemDescription: {
    '* + &': {
      marginTop: theme.spacing(3),
    },
  },
  itemButtonWrapper: {
    paddingTop: theme.spacing(4),
  },
  itemButton: {
    margin: theme.spacing(0.5, 0),
  },
  itemImage: {
    width: '100%',
    height: '100%',
    ...theme.breakpoints.keys.reduce(
      (acc, breakpoint) => ({
        ...acc,
        [theme.breakpoints.up(breakpoint)]: {
          marginBottom: theme.spacing(-getBreakPointFactor(breakpoint) * 2),
        },
      }),
      {},
    ),
  },
  itemImageIntech: {
    marginBottom: 0,
  },
});

export type Props = {
  ...$Exact<WithStyles<ClassKey>>,
  ...$Exact<WithNodePageProps<Prismic404Page>>,
};

const NotFoundPage = ({ classes, node }: Props) => {
  const theme = useTheme<Theme>();
  const isMdUp = useMediaQuery(theme.breakpoints.up('md'));
  const patternData = useBackgroundPatternFile({
    backgroundPattern: 'Pattern1',
  });

  const envNamespace = process.env.GATSBY_FRONTEND_ENV_VAR_NAMESPACE;
  const getErrorImageAttr = {
    INTECH: {
      src: imageIntech,
      alt: 'Error page warning modal',
      className: classnames(classes.itemImage, classes.itemImageIntech),
    },
    INVELON: {
      src: image,
      alt: 'Girl with vr glasses',
      className: classes.itemImage,
    },
    AURORA: {
      src: image,
      alt: 'Girl with vr glasses',
      className: classes.itemImage,
    },
    ATECA: {
      src: image,
      alt: 'Girl with vr glasses',
      className: classes.itemImage,
    },
  }[envNamespace || 'INVELON'];

  return (
    <div className={classes.root}>
      <FileImage file={patternData} className={classes.backgroundImage} />
      <Container component={ResPadding} vertical className={classes.wrapper}>
        <Grid
          container
          spacing={isMdUp ? 6 : 4}
          alignItems="center"
          className={classes.itemsGridContainer}
        >
          <Grid item xs={12} md={6} className={classes.itemPrimaryWrapper}>
            <RichText {...node?.data?.title} className={classes.itemTitle} />
            <RichText
              {...node?.data?.description}
              className={classes.itemDescription}
            />
            <div className={classes.itemButtonWrapper}>
              {map(
                node?.data?.buttons,
                (item, index: number) =>
                  item && (
                    <StyledNotchedButton
                      key={index}
                      component={FieldLink}
                      field={item?.link}
                      variant="contained"
                      color="primary"
                      aria-label={item?.label?.text}
                      className={classes.itemButton}
                    >
                      {item?.label?.text || ''}
                    </StyledNotchedButton>
                  ),
              )}
            </div>
          </Grid>
          <Grid item xs={12} md={6} className={classes.itemSecondaryWrapper}>
            <img alt="" {...getErrorImageAttr} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default compose(
  withNodePage<Prismic404Page, *>({
    getNode: data => data?.prismic404Page,
  }),
  withStyles<ClassKey, *, Props>(styles, { name: 'NotFoundPage' }),
)(NotFoundPage);

export const query = graphql`
  query NotFoundPageQuery($prismicLocaleId: String) {
    prismic404Page(lang: { eq: $prismicLocaleId }) {
      id
      lang
      uid
      first_publication_date
      last_publication_date
      data {
        meta_title {
          text
        }
        meta_description {
          text
        }
        meta_keywords {
          meta_keyword {
            text
          }
        }
        meta_image {
          alt
          url
          dimensions {
            width
            height
          }
        }
        title {
          text
          html
        }
        description {
          text
          html
        }
        buttons {
          label {
            text
          }
          link {
            link_type
            url
            target
          }
        }
      }
    }
  }
`;
